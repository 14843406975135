:root {
	/*
	 * These are our main colors. Please use them, e.g.: `color: var(--primaryColor)`.
	 */
	--navText: rgb(0, 0, 0, 0.5);
	--footerText: rgb(0, 0, 0, 0.7);
	--primaryColor: #abe7f5;
	--secondaryColor: #4c83e0;
	--tertiaryColor: #3421ff;
}

/*****************
	Bootstrap
******************/

.bg-custom {
	background-color: var(--secondaryColor);
}

.navbar {
	padding: .7rem 1rem;
}

@media (min-width: 992px) {
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
	color: var(--navText);
}

.navbar-light .navbar-nav .nav-link {
	transition: color 0.2s;
	color: var(--navText);
}

.btn-primary {
	background-color: var(--secondaryColor) !important;
	border-color: var(--secondaryColor) !important;
}

.btn-primary:not(:disabled):not(.disabled):hover {
	background-color: var(--tertiaryColor) !important;
	border-color: var(--tertiaryColor) !important ;
}

.btn-outline-primary {
	color: var(--secondaryColor);
	border-color: var(--secondaryColor);
}

.btn-outline-primary:not(:disabled):not(.disabled):hover {
	color: white;
	border-color: var(--tertiaryColor);
	background-color: var(--tertiaryColor);
}

.btn-outline-primary:active {
	background-color: var(--tertiaryColor) !important;
	border: inherit !important;
}

.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn:focus,
.btn:active {
	outline: none !important;
	box-shadow: none !important;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
	display: flex;
}

.carousel-inner {
	position: relative;
	overflow: hidden;
	height: 40rem;
}

.carousel-indicators li {
	box-sizing: content-box;
	flex: 0 1 auto;
	width: 30px;
	height: 3px;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: var(--secondaryColor);
	background-clip: padding-box;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	opacity: 0.5;
	transition: opacity 0.6s ease;
}

.carousel-indicators .active {
	opacity: 1;
	background-color: var(--tertiaryColor);
}

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233421ff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233421ff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

/*****************
	Custom CSS
******************/

.debug {
	border: 1px dotted red;
}

.w-33 {
	width: 33.33%;
}

body {
	background-color: var(--primaryColor) !important;
}

.title {
	text-decoration-style: solid;
	text-decoration: underline;
	text-decoration-color: var(--secondaryColor);
}

.boxWrapper {
	width: 100%;
	height: 100%;
	background-color: white;
	border-radius: 5px;
	padding: 15px 0px 15px 0px;
}

.loginScreen {
	max-width: 768px;
}

.navbarTitle {
	font-size: 1.5rem;
	padding-right: 1rem;
}

.navElem,
.navElem a {
	vertical-align: middle;
	font-size: 1rem;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: 1px;
}

.navElem:hover,
.navElem a:hover {
	color: var(--tertiaryColor) !important;
}

.navElem:visited,
.navElem a:visited {
	background-color: inherit;
}

.footer {
	display: inline-flex;
	position: fixed;
	width: 100%;
	bottom: 0;
	height: 30px;
	background-color: white;
	letter-spacing: 0.5px;
	font-size: 0.8rem;
}

.footer .footerRight {
	position: relative;
	align-self: center;
	text-align: center;
	margin-left: auto;
}

.footer .footerText {
	color: var(--footerText);
	margin-bottom: 0;
}

.footer .footerLink {
	color: var(--footerText);
	text-decoration: none;
	padding-left: 1rem;
	cursor: pointer;
}

.footer .footerLink:hover {
	transition: color 0.2s;
	color: var(--tertiaryColor);
}

.overview {
	margin-top: 1rem;
}

.overviewCard {
	min-width: 15rem;
	max-width: 15rem;
	min-height: 20rem;
	max-height: 20rem;
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: center;
}

.overviewCard.completed,
.overviewCard .completed {
	border-color: rgba(0, 128, 0, 0.75);
}

.overviewCard .completed {
	background-color: rgba(0, 128, 0, 0.07);
}

.overviewTabCard {
	border-top-left-radius: 0rem;
	border-top-right-radius: 0rem;
}

.overviewTab {
	background-color: white;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	border-bottom: 0px;
}

.overviewTab .overviewNavLink.active {
	color: white;
	background-color: var(--secondaryColor) !important;
	border-color: var(--secondaryColor) !important;
}

.overviewTab .overviewNavLink:hover {
	background-color: var(--tertiaryColor);
	border-color: var(--tertiaryColor);
	color: white;
}

.overviewNavLink {
	color: black;
	width: 33.33%;
	justify-content: center;
	display: flex;
}

.badgeTheory {
	background-color: blue;
	color: white;
}

.badgeGame {
	background-color: orange;
	color: white;
}

.badgeQuiz {
	background-color: green;
	color: white;
}

.badgeStory {
	background-color: rgb(140, 12, 190);
	color: white;
}

.badgeExplanation {
	background-color: rgb(0, 174, 255);
	color: white;
}

.carouselSlide {
	color: black;
	background-color: white;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	margin-top: 2rem;
}

.carouselSlideBox {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0% 10% 0% 10%;
	font-size: large;
	height: 100%;
}

.centerBox {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.slideSubText {
	margin-top: 1rem;
}

.nextPageButtonRowQuiz {
	margin-bottom: 6rem;
	margin-right: 4rem;
	justify-content: end;
}

.nextPageButtonRowTheory {
	margin-bottom: 4rem;
	margin-right: 1rem;
	justify-content: end;
}

.textLineThrough {
	text-decoration: line-through;
}

.colorPrimary {
	color: var(--primaryColor);
}

.colorSecondary {
	color: var(--secondaryColor);
}

.bgLightGrey {
	background-color: rgb(241, 241, 241);
}

.QuestionnaireLabel {
	font-size: 0.75rem;
	color: #6c757d;
	text-align: center;
	padding: 0 5px 0 5px;
}

.questionnaireSeparator {
	border-bottom: 1px solid rgb(214, 214, 214);
	margin-right: -15px;
	margin-left: -15px;
}

.customToast {
	position: fixed;
	top: 1rem;
	width: 100%;
	z-index: 5;
}

.infoBoxOuter {
	display: flex;
	cursor: default;
	margin-left: 0.25rem;
	align-self: center;
	color: var(--secondaryColor);
	border: 1px solid var(--secondaryColor);
	border-radius: 0.25rem;
}

.infoBoxOuterInteractive:hover {
	cursor: help;
	color: var(--tertiaryColor);
	border: 1px solid var(--tertiaryColor);
}

.infoBoxText {
	padding: 0.375rem 0.7rem;
}

.infoIcon {
	font-size: 1.5rem;
	padding-right: 0.7rem;
}

.green,
.correct,
.success {
	color: green;
	border-color: green;
}

.red,
.incorrect,
.error {
	color: red;
	border-color: red;
}

.blue {
	color: blue;
}

.orange {
	color: orange;
}

.unknownPageCode {
	font-size: 15vh;
	font-weight: 500;
	line-height: 15vh;
}

.unknownPageText {
	font-weight: 500;
}

.achievementImage {
	transition: transform 0.2s;
}

.achievementImage:hover {
	transform: scale(1.5);
}

/*
 The height of gameDestination sets the maximum height of the game frame
 and therefore must be the height of the game (currently 600)!
 */
#gameDestination {
	height: 600px !important;
}

.ghLink img {
	height: 1.15rem;
	width: 1.15rem;
	transition: transform 0.2s;
}

.ghLink img:hover {
	transform: scale(1.2);
}

.statisticsBox {
	border-radius: 0.25rem;
	background-color: rgba(0, 0, 0, 0.02);
	padding: 0.5rem;
	margin-bottom: 0.75rem;
}

.statisticsBoxClickable {
	cursor: pointer;
}

.statisticsBoxClickable:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.smallProgress {
	height: 0.5rem !important;
}

.separator {
	border-bottom: 1px solid rgb(0, 0, 0, 0.2);
}

div.tabNavWrapper {
	border-color: var(--secondaryColor);
}

.tabNav a.tabNavElem {
	color: rgb(61, 61, 61);
	border-color: transparent transparent var(--secondaryColor);
}

.tabNav a.tabNavElem.active {
	color: var(--secondaryColor);
	border-color: var(--secondaryColor) var(--secondaryColor) #fff;
}

.tabNav a.tabNavElem:not(.active):hover {
	border-color: var(--tertiaryColor);
}

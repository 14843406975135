.arrowKey {
	margin: 0.1rem 0.1rem 0.1rem 0.1rem;
}

.arrowRight,
.arrowLeft,
.arrowUp,
.arrowDown {
	border: solid white;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 4px;
}

.arrowRight {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

.arrowLeft {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}

.arrowUp {
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
}

.arrowDown {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.arrowKeysVertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	flex-direction: column;
	justify-content: center;
}

.btnWrapper {
	display: inline-flex;
	width: 100%;
}

.btnWrapper .btnWrapperLeft,
.btnWrapper .btnWrapperRight {
	position: relative;
	align-self: center;
	padding-top: 0.5rem;
}

.btnWrapperRight {
	margin-left: auto;
}

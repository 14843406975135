.landingpage {
	top: 0 !important;
	height: 100%;
	background-image: linear-gradient(#afe4e4, #d4f4f4);
}

.devBadge {
	background-color: blue;
	color: white;
}

.managementBadge {
	background-color: orange;
	color: white;
}

.designBadge {
	background-color: limegreen;
	color: white;
}

.storyBadge {
	background-color: rgb(50, 200, 205);
	color: white;
}
